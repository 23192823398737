import { StackDispatcherApi } from './disp_http';

export class DispatcherComonModel implements DataModel {
  public type: string;
  public http: StackDispatcherApi;

  constructor(type: string) {
    this.type = type;
    this.http = new StackDispatcherApi();
  }

  get model(): string {
    return 'DispatcherComonModel';
  }

  get description(): string {
    return '';
  }

  get fields(): StackField[] {
    return [{ field: 'title', noState: true, text: 'title', type: 'String' }];
  }

  get length(): number {
    return 0;
  }

  get hier(): boolean {
    return false;
  }

  public async getMainProperties(): Promise<{}> {
    // Запрашиваем список св-в
    const namesProp = await this.getParameterNamesList();
    if (!namesProp) {
      return [];
    }
    // Запрашиваем значения параметров
    const selection = await this.getParameterValue(namesProp);
    return selection;
  }

  public async saveMainProperties(record: {}): Promise<boolean> {
    try {
      const arrParameters = [];
      for (const property in record) {
        // @ts-ignore
        const res = this.setParameter(property, record[property] as string);
        arrParameters.push(res);
      }

      const request = [{ [this.type]: arrParameters }];
      await this.http.sendRequest(request);
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async getRecords(params?: StackHttpRequestTaskParam): Promise<StackTableRow[]> {
    // Запрашиваем данные об именах диспетчеров или веб-сервисов
    const names = await this.getNameList();
    if (!names) {
      return [];
    }

    // Запрашиваем имена параметров, доступных для указанного типа
    const parameters = await this.getParametersList(names);
    // Запрашиваем значения параметров
    const selection = await this.getSelections(names, parameters);

    let data: StackTableRow[] = selection;
    if (params) {
      // Если указан номер записи, фильтруем только по нему
      if (params.номерЗаписи) {
        data = selection.filter((row: StackTableRow) => row.$номерЗаписи === params.номерЗаписи);
        // Если введён "быстрый поиск"
      } else if (params.поискСтрока && params.поискПоля) {
        const regStr: string = params.поискСтрока?.toLowerCase();
        const fields = params.поискПоля.split(',');
        data = selection.filter((row: StackTableRow) => {
          for (const field of fields) {
            if (row[field]?.toString().toLowerCase().search(regStr) !== -1) {
              return true;
            }
          }
          return false;
        });
      } else {
        data = selection;
      }
    } else {
      data = selection;
    }

    return data;
  }

  public async saveRecord(record: StackTableRow, params?: any): Promise<boolean> {
    try {
      await this.renameIfNeeded(params.initTitle, record);

      const arrParameters = [];
      for (const property in record) {
        if (property.indexOf('$') === -1 && property !== 'title' && property !== 'state') {
          const res = this.setParameter(property, record[property] as string);
          arrParameters.push(res);
        }
      }
      const request = [{ [this.type]: [{ params: [record.title], type: 'method', Item: arrParameters }] }];
      await this.http.sendRequest(request);
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async initRecord(params?: any): Promise<StackTableRow> {
    let prototypeIndex = params.item?.номерЗаписи || 0;
    if (prototypeIndex <= 0) {
      return {};
    }

    prototypeIndex -= 1;
    const records = await this.getRecords();
    if (prototypeIndex >= records.length) {
      return {};
    }

    const prototype = records[prototypeIndex];
    prototype.title += ' copy';
    prototype.$номерЗаписи = 'new';
    return prototype;
  }

  public async createRecord(record: StackTableRow): Promise<number | null> {
    try {
      const request = [{ [this.type]: [{ params: [record.title], type: 'method', Add: [] }] }];
      await this.http.sendRequest(request);

      const arrParameters = [];
      for (const property in record) {
        if (property.indexOf('$') === -1 && property !== 'title' && property !== 'state' &&
            property !== 'номерЗаписи' && property !== 'тип') {
          const res = this.setParameter(property, record[property] as string);
          arrParameters.push(res);
        }
      }
      if (arrParameters.length > 0) {
        const requestSave = [{ [this.type]: [{ params: [record.title], type: 'method', Item: arrParameters }] }];
        await this.http.sendRequest(requestSave);
      }

      return 1;
    } catch (error: AnyException) {
      return null;
    }
  }

  public async deleteRecords(records: StackTableRow[]): Promise<boolean> {
    try {
      const request = this.setMethodsRequest(records, 'Delete');
      await this.http.sendRequest(request);
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async renameIfNeeded(initTitle: string, record: StackTableRow) {
    if (!initTitle || initTitle === record.title) {
      return;
    }

    try {
      const request = [{ [this.type]: [this.setMethod(initTitle, 'Rename', [record.title])] }];
      await this.http.sendRequest(request);
    } catch {
      //
    }
  }

  public async Add(name: string): Promise<boolean> {
    try {
      const request = [{ [this.type]: [{ params: [name], type: 'method', Add: [] }] }];
      await this.http.sendRequest(request);
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async Delete(name: string): Promise<boolean> {
    try {
      const request = [{ [this.type]: [this.setMethod(name as string, 'Delete')] }];
      await this.http.sendRequest(request);
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async Save(name: string, params?: any): Promise<boolean> {
    try {
      const arrParameters = [];
      for (const property in params) {
        if (property.indexOf('$') === -1 && property !== 'title' && property !== 'state') {
          const res = this.setParameter(property, params[property] as string);
          arrParameters.push(res);
        }
      }
      const request = [{ [this.type]: [{ params: [name], type: 'method', Item: arrParameters }] }];
      await this.http.sendRequest(request);
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async execute(): Promise<boolean> {
    return false;
  }

  public async executeMethod(method: string, records: StackTableRow[]): Promise<boolean> {
    try {
      const request = this.setMethodsRequest(records, method);
      await this.http.sendRequest(request);
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async executeDispMethod(method: string): Promise<boolean> {
    try {
      const request = [{ [this.type]: [{ type: 'method', [method]: [] }] }];
      await this.http.sendRequest(request);
      return true;
    } catch (error: AnyException) {
      return false;
    }
  }

  public async getResultExecuteDispMethod(method: string): Promise<string> {
    try {
      const request = [{ [this.type]: [{ type: 'method', [method]: [] }] }];
      const result = await this.http.sendRequest(request);
      if (result) {
        return result[0][this.type][0][method].result;
      }
      return '';
    } catch (error: AnyException) {
      return 'Не удалось выполнить метод';
    }
  }

  public setMethodsRequest(records: StackTableRow[], action: string) {
    const arrServices = [];
    for (const rec in records) {
      arrServices.push(this.setMethod(records[rec].title as string, action));
    }
    const request = [{ [this.type]: arrServices }];
    return request;
  }

  // передает объекту метод на выполнение
  public setMethod(title: string, method: string, params?: any) {
    if (params) {
      return { params: [title], type: 'method', Item: [{ params, type: 'method', [method]: [] }] };
    } else {
      return { params: [title], type: 'method', Item: [{ type: 'method', [method]: [] }] };
    }
  }

  // создает объект для присвоения значения параметру
  public setParameter(name: string, value: string) {
    return { params: [name], type: 'property', Parameter: [{ params: [value], SetValue: [] }] };
  }

  // Список с-в, корневого элемента , например WebServer
  public async getParameterNamesList(): Promise<string[]> {
    const request = [];
    request.push({ [this.type]: this.getParameterNamesRequest() });
    const result = (await this.http.sendRequest(request))[0];
    if (result && result[this.type] && result[this.type][0]) {
      return JSON.parse(result[this.type][0].ParameterNames.result);
    }
    return [];
  }

  public async getNameList(): Promise<string[]> {
    const request = [];
    request.push({ [this.type]: this.getNamesRequest() });

    const result = (await this.http.sendRequest(request))[0];
    if (result && result[this.type] && result[this.type][0]) {
      return JSON.parse(result[this.type][0].Names.result);
    }
    return [];
  }

  public async getParametersList(names: string[]) {
    const request = [];
    request.push({ [this.type]: this.getParametersRequest(names) });
    const result = (await this.http.sendRequest(request))[0];
    if (result && result[this.type] && result[this.type][0] && result[this.type][0].Item && result[this.type][0].Item[0]) {
      return JSON.parse(result[this.type][0].Item[0].ParameterNames.result);
    }
    return '';
  }

  protected async getParameterValue(paramNames: object) {
    const resObj = { [this.type]: [] as object[] };
    resObj[this.type] = this.getMainServiceParameters(paramNames);
    const result = this.parseMainServiceParameters((await this.http.sendRequest([resObj]))[0][this.type]);
    return result;
  }

  public async getSelections(serviceNames: any, paramNames: object) {
    const resObj = { [this.type]: [] as object[] };
    serviceNames.forEach((serviceName: string) => {
      if (serviceName !== 'SetupDispatcher') {
        resObj[this.type].push(this.getServiceParameters(serviceName, paramNames));
      }
    });
    const result = this.parseResult((await this.http.sendRequest([resObj]))[0][this.type]);
    return result;
  }

  public parseResult(res: any) {
    const result: StackTableRow[] = [];
    let index = 0;
    res.forEach((service: object) => {
      result.push(Object.assign({ $номерЗаписи: ++index }, {}, this.parseServiceParameters(service)));
    });
    return result;
  }

  // парсим блок с диспетчером или вебсервером, преобразуем его в объект { title: название, [параметр: значение] }
  public parseServiceParameters(service: any) {
    let res = { title: '' };
    if (service && service.params && service.params[0]) {
      res.title = service.params[0];
    }
    if (service && service.Item) {
      service.Item.forEach((parameter: object) => {
        res = Object.assign(res, {}, this.parseParameter(parameter));
      });
    }
    return res;
  }

  public parseMainServiceParameters(parameters: any) {
    let res = {};
    parameters.forEach((parameter: object) => {
      res = Object.assign(res, {}, this.parseParameter(parameter));
    });
    return res;
  }

  // парсим блок со значеним параметра и возвращаем его в виде { параметр: значение }
  public parseParameter(parameter: any) {
    let name = '';
    let value = '';
    if (parameter && parameter.params && parameter.params[0]) {
      name = parameter.params[0];
    }
    if (parameter && parameter.Parameter && parameter.Parameter[0] && parameter.Parameter[0].Value && parameter.Parameter[0].Value.result) {
      value = parameter.Parameter[0].Value.result;
    }
    // проверка на состояния проводится отдельно
    if (parameter && parameter.State && parameter.State.result) {
      name = 'state';
      value = parameter.State.result;
    }
    return { [name]: value };
  }

  // блок запроса значения параметров по одному вебсерверу или диспетчеру
  public getMainServiceParameters(paramNames: any) {
    const res = [] as object[];
    paramNames.forEach((paramName: string) => {
      res.push(this.getParamValue(paramName));
    });
    return res;
  }

  // блок запроса значения параметров по одному вебсерверу или диспетчеру
  public getServiceParameters(serviceName: string, paramNames: any) {
    const res = { params: [] as string[], Item: [] as object[] };
    res.params.push(serviceName);
    paramNames.forEach((paramName: string) => {
      res.Item.push(this.getParamValue(paramName));
    });
    // проверка на состояния проводится отдельно
    res.Item.push({ State: [] });
    return res;
  }

  // блок запроса значения параметра
  public getParamValue(name: string) {
    const res = { params: [] as string[], type: 'property', Parameter: [{ Value: [] }] };
    res.params.push(name);
    return res;
  }

  // блок запроса свойств диспетчера или вебсервера
  public getParametersRequest(names: string[]) {
    const res = [];
    res.push({
      params: [names.length - 1],
      Item: [
        {
          ParameterNames: [],
        },
      ],
    });
    return res;
  }

  // Формирует блок для запроса имени вебсерверов, диспетчеров, все что имеет атрибут "имя"
  public getNamesRequest() {
    const res = [];
    res.push(this.getMethod('Names'));
    return res;
  }

  public getParameterNamesRequest() {
    const res = [];
    res.push(this.getMethod('ParameterNames'));
    return res;
  }

  public getMethod(name: string, param?: string) {
    let res = {};
    if (param) {
      res = Object.assign(res, {}, { params: [param], [name]: [] });
      return res;
    } else {
      return { [name]: [] };
    }
  }
}
