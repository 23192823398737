import { store, StackApi } from '@/stackEngine';
import Axios from 'axios';

const axios = Axios.create({
  method: 'post',
});
export class StackDispatcherApi extends StackApi {
  // метод аутентификации
  public async getToken(password: string): Promise<string | undefined> {
    const response = await this.sendRequest({ secret: password });
    const newToken = response['S-Session-Token'];
    if (!newToken || newToken.startsWith('Неверный пароль')) {
      return undefined;
    }
    return newToken;
  }

  // Посылаем запрос на бекенд и сразу парсим результат
  public async sendRequest(request: object): Promise<any> {
    const URL = store.getters.getApiHost();
    const headers = {} as any;
    const token = store.getters.getToken();
    if (token) {
      headers['s-session-token'] = token;
    }
    const timeout = store.getters.getTimeout();

    const result = await axios.post(URL, request, { headers, timeout })
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          store.commit('SET_TOKEN', ''); // окно аутентификации
          return Promise.reject(error);
        }
      });

    if (result) {
      return result;
    }
    return '';
  }
}
