import { DispatcherComonModel } from './dispComonModel';

export class DispatcherServiceModel extends DispatcherComonModel {
  get description(): string {
    return '';
  }

  // Список с-в, корневого элемента , например WebServer
  public async getParameterNamesList(): Promise<string[]> {
    const request = this.getParameterNamesRequest();
    const result = (await this.http.sendRequest(request))[0];
    if (result) {
      return JSON.parse(result.ParameterNames.result);
    }
    return [];
  }

  protected async getParameterValue(paramNames: object) {
    const resObj = this.getMainServiceParameters(paramNames);
    const result = this.parseMainServiceParameters((await this.http.sendRequest(resObj)));
    return result;
  }

  public async changePassword(pas: string) {
    const request = [{ params: [pas], type: 'method', ChangePassword: [] }];
    await this.http.sendRequest(request);
  }
}
