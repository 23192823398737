
import Vue from 'vue';
import ContactButtons from './ContactButtons.vue';
import { DispatcherServiceModel } from '../../../../tasks/setup/utils/dispatcherServiceModel';
import credits from '../../../themes/credits_stack';

export default Vue.extend({
  components: {
    ContactButtons,
  },
  data() {
    return {
      versionDispatcher: '' as String,
      dispatcherService: new DispatcherServiceModel(''),
      licModel: new this.$HttpModel('Лицензии'),
      licInfo: null as null | StackTableRow,
      eClick: 0,
      credits,
    };
  },
  computed: {
    traceLvl(): number {
      return this.$store.getters.traceLvl();
    },
    isAdmin(): boolean {
      return this.$store.getters.isUserAdmin();
    },
    getFrontendVersion() {
      return this.$store.getters.getFrontendVersion();
    },
    getBuildInfo() {
      return `собран ${this.$stackDate.format(this.$store.getters.getBuildDateTime(), 'DD.MM.YYYYг. в HH:MM:SS')} ${this.$store.getters.getbuildRevision()}`;
    },
    getBackEndVersion(): String {
      if (this.getCurrentTaskFullName === 'Настройка диспетчера') {
        return this.versionDispatcher;
      } else {
        return this.$store.getters.getBackEndVersion();
      }
    },
    theme(): any {
      return require('../../../themes/jkh/bg_pen.png');
    },
    getCurrentTaskFullName(): string {
      return this.$store.getters.getCurrentTaskFullName();
    },
  },
  async created() {
    if (!this.$store.getters.isCurrentTaskExternal()) {
      const licInfos = await this.licModel.getRecords();
      if (licInfos.length > 0) {
        this.licInfo = licInfos[0];
      }
    }
  },
  async mounted() {
    if (this.getCurrentTaskFullName === 'Настройка диспетчера') {
      const obj: any = await this.dispatcherService.getMainProperties();
      if (obj && obj.Version) {
        this.versionDispatcher = obj.Version;
      }
    }
  },
  methods: {
    async onClickReg() {
      const http = new this.$StackApi();
      const res = await http.registerLicense();
      this.$store.commit('SET_LICENSE_KEY', res?.tasks?.[0]?.result?.dbCode);
    },
  },
});
